import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ReciclayaService } from './services/reciclaya.service';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment.prod';
import { ConsentPage } from './pages/consent/consent.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  token: any;
  public activeLang = 'es';
  error: any;
  loaded = false;


  constructor(private platform: Platform,
              public router: Router,
              public api: ReciclayaService,
              private translate: TranslateService,
              private update: SwUpdate,
              private modalController: ModalController) {

                console.log('Inicializamos la app');
                this.updateClient();

                if(localStorage.getItem('token') && localStorage.getItem('token') != '') {
                  this.api.getToken();

                  // Obtenemos datos de usuario
                  this.api.getPrivate(`user/`).subscribe(async (data: any) => {
                    if (data) {
                      console.log('Get user data: ', data);
                      this.api.user = data;

                      // comprobamos ultimos consent aceptados por el usuario
                      if(environment.consent != data.consent) {
                        console.log('Nuevos terminos, necesario actualizar.');
                        this.presentModalConsent();
                      }

                      this.loaded = true;

                    }
                  }, error => {
                    console.log(error);
                    this.api.token = null;
                    localStorage.removeItem('token');
                    this.loaded = true;
                    let tokenExpired = this.translate.instant('ERROR.TOKEN_EXPIRED');
                    this.api.showToast(tokenExpired, 'danger', 'top');
                    this.router.navigateByUrl('/login');

                  });
                } else {
                  this.loaded = true;
                }

                this.translate.setDefaultLang('es');
                // Si tenemos lang guardado en localstorage seteamos ese idioma
                if(localStorage.getItem('lang') && localStorage.getItem('lang') != ''){
                  this.activeLang = localStorage.getItem('lang');
                } else {
                  localStorage.setItem('lang', this.activeLang);
                }
                this.translate.use(this.activeLang);

              }

 
  public changeLang(lang) {
    this.activeLang = lang;
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    window.location.reload();
  }

  public updateClient() {

    if(!this.update.isEnabled) {
      console.log('Not Enabled');
      return;
    }

    this.update.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          if(confirm('Actualización disponible para la aplicación, por favor confirme.')) {
            this.update.activateUpdate().then(() => location.reload());
          }
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  doRefresh($event) {
    window.location.reload();
  }

  async presentModalConsent() {
    const modal = await this.modalController.create({
      component: ConsentPage,
      backdropDismiss: false
    });
    return await modal.present();
  }


}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ReciclayaService {
  token: any;
  tokenCarrefour: any;
  email: any;
  user: any;
  scanData: any;


  constructor(private http: HttpClient,
              public toastCtrl: ToastController) { }
  /**
   * Función para realizar llamadas a la API
   * @param query Consulta a la API
   */
     public get(query: string) {
      const recycl3rURL = environment.api + query;
  
      const headers = new HttpHeaders({
          'Content-type': 'application/json',
          'x-api-key': environment.token,
          'Accept': 'application/json'
      });
      return this.http.get(recycl3rURL, { headers } );
    }


      /**
   * Función para realizar llamadas a la API
   * @param query Consulta a la API
   */
       public getPrivate(query: string) {
        const recycl3rURL = environment.api + query;
    
        const headers = new HttpHeaders({
            'Content-type': 'application/json',
            'x-api-key': environment.token,
            'Authorization': this.token,
            'Accept': 'application/json'
        });
        return this.http.get(recycl3rURL, { headers });
      }

      public getAuthToken(query: string, token) {
        const recycl3rURL = environment.api + query;
    
        const headers = new HttpHeaders({
            'Content-type': 'application/json',
            'x-api-key': environment.token,
            'Authorization': token,
            'Accept': 'application/json'
        });
        return this.http.get(recycl3rURL, { headers });
      }
  
    public post(query, body) {
      const recycl3rURL = environment.api + query ;
  
      const headers = new HttpHeaders({
          'Content-type': 'application/json',
          'x-api-key': environment.token,
          'Accept': 'application/json'
      });
      return this.http.post(recycl3rURL, body, {headers});
    }

    public postPrivate(query, body) {
      const recycl3rURL = environment.api + query ;
  
      const headers = new HttpHeaders({
          'Content-type': 'application/json',
          'x-api-key': environment.token,
          'Authorization': this.token,
          'Accept': 'application/json'
      });
      return this.http.post(recycl3rURL, body, {headers});
    }

    public putPrivate(query, body) {
      const recycl3rURL = environment.api + query ;
  
      const headers = new HttpHeaders({
          'Content-type': 'application/json',
          'x-api-key': environment.token,
          'Authorization': this.token,
          'Accept': 'application/json'
      });
      return this.http.put(recycl3rURL, body, {headers});
    }
    

    public saveToken(token) {
      localStorage.setItem('token', token);
      this.token = token;
    }

    public saveTokenCarrefour(token) {
      localStorage.setItem('tokenCarrefour', token);
      this.tokenCarrefour = token;
    }

    public getToken() {
      this.token = localStorage.getItem('token');
    }

    public getTokenCarrefour() {
      this.tokenCarrefour = localStorage.getItem('tokenCarrefour');
    }

    async showToast(msg, colors, pos) {
    
      const toast = await this.toastCtrl.create({
        message: msg,
        duration: 20000,
        buttons: [
         {
            side: 'end',
            icon: 'close-outline',
            role: 'cancel',
            handler: () => {
              console.log('Close clicked');
            }
          }
        ],
        color: colors,
        position: pos
      });
      toast.present();
    }
  
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ReciclayaService } from 'src/app/services/reciclaya.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.page.html',
  styleUrls: ['./consent.page.scss'],
})
export class ConsentPage implements OnInit {

  isSubmitted = false;
  acceptTerm = false;
  loading = false;
  term = false;
  privacy = false;
  consent: boolean;
  disable = false;
  urlLicense = location.protocol + '//' + location.host+ '/license';
  urlPrivacy = location.protocol + '//' + location.host+ '/privacy';

  constructor(private api: ReciclayaService,
              private modalCtrl: ModalController) { }

  ngOnInit() {
  }


  sendConsent() {
    if(this.term && this.privacy) {
      this.acceptTerm = true;
     
        let body = {
          consent: environment.consent
        }
    
        this.api.postPrivate(`user/consent`, body).subscribe((data: any) => {
    
            console.log('data', data);
    
            if(data && !data.errors) {
              this.api.showToast(data.message, 'success', 'top');
              this.loading = false;
              this.modalCtrl.dismiss();
              window.location.reload();
            } else {
              console.log(data.errors[0]);
              this.loading = false;
            }
    
        }, error => {
          console.log(error);
          if(error.error.errors[0]) {
            this.api.showToast(error.error.errors[0], 'danger', 'top');
          }
          this.loading = false;
        });
        
      }
    }
 
}
